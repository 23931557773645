/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import SimpleReactLightbox from "simple-react-lightbox"
 
export const wrapRootElement = ({ element }) => {
  return <SimpleReactLightbox>{element}</SimpleReactLightbox>
}